export const countryConfig = {
  countries: [
    {
      countryCode: 'US',
      langCodes: ['en-US', 'es-US'],
      sitemapLocales: ['us/en', 'us/es'],
      currencyCodes: ['USD'],
      twitter: 'Square',
      facebook: 'square',
      instagram: 'square',
      launchDatetime: '2000-01-01 05:00:00 UTC',
    },
    {
      countryCode: 'CA',
      langCodes: ['en-CA', 'fr-CA'],
      sitemapLocales: ['ca/en', 'ca/fr'],
      currencyCodes: ['CAD'],
      twitter: 'SquareCanada',
      facebook: 'squarecanada',
      instagram: 'squarecanada',
      launchDatetime: '2000-01-01 05:00:00 UTC',
    },
    {
      countryCode: 'JP',
      langCodes: ['ja-JP'],
      sitemapLocales: ['jp/ja'],
      currencyCodes: ['JPY'],
      twitter: 'SquareJapan',
      facebook: 'squarejp',
      instagram: 'squarejapan',
      launchDatetime: '2000-01-01 05:00:00 UTC',
    },
    {
      countryCode: 'AU',
      langCodes: ['en-AU'],
      sitemapLocales: ['au/en'],
      currencyCodes: ['AUD'],
      twitter: 'SquareAU',
      facebook: 'squareaustralia',
      instagram: 'squareau',
      launchDatetime: '2000-01-01 05:00:00 UTC',
    },
    {
      countryCode: 'FR',
      langCodes: ['fr-FR'],
      sitemapLocales: ['fr/fr'],
      earlyAccessPath: '/fr/fr/france-ap',
      currencyCodes: ['EUR'],
      twitter: 'squarefrance',
      facebook: 'squarefr',
      instagram: 'squarefrance',
      launchDatetime: '2000-01-01 05:00:00 UTC',
    },
    {
      countryCode: 'GB',
      langCodes: ['en-GB'],
      sitemapLocales: ['gb/en'],
      currencyCodes: ['GBP'],
      twitter: 'SquareUK',
      facebook: 'squareunitedkingdom',
      instagram: 'squareuk',
      launchDatetime: '2000-01-01 05:00:00 UTC',
    },
    {
      countryCode: 'IE',
      langCodes: ['en-IE'],
      sitemapLocales: ['ie/en'],
      earlyAccessPath: '/ie/en/irelandeap',
      currencyCodes: ['EUR'],
      twitter: 'squareireland',
      facebook: 'squareireland',
      instagram: 'squareireland',
      launchDatetime: '2000-01-01 05:00:00 UTC',
    },
    {
      countryCode: 'ES',
      langCodes: ['es-ES', 'ca-ES'],
      sitemapLocales: ['es/es', 'es/ca'],
      currencyCodes: ['EUR'],
      earlyAccessPath: '/es/es/espana-aa',
      twitter: 'squareespana',
      facebook: 'squareespana',
      instagram: 'squareespana',
      launchDatetime: '2022-01-24 18:00:00 UTC',
      earlyAccessPermittedPaths: ['regex ^/es/es(./*)*?$'],
      localeRewrites: {
        'es/ca': {
          langCode: 'ca-ES',
          nonPermittedPath: 'regex ^/es/ca(./*)*?$',
          expiryDatetime: '2022-04-20 16:00:00 UTC',
          redirectPath: '/es/es/',
        },
      },
    },
  ],
};
