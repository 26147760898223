// countryConfig source of truth is in XMS: https://github.com/squareup/xms/blob/main/config/country_config.yml
import { countryConfig } from '../constants/country-config';

export type CountryConfig = {
  countryCode?: string;
  langCodes?: string[];
  sitemapLocales?: string[];
  currencyCodes?: string[];
  twitter?: string;
  facebook?: string;
  instagram?: string;
  launchDatetime?: string;
  earlyAccessPath?: string;
  earlyAccessPermittedPaths?: string[];
  localeRewrites?: Record<string, Record<string, string>>;
};

const countryConfigs: CountryConfig[] = countryConfig['countries'];

const countryConfigHelper = {
  getCountryConfig: (country: string): CountryConfig =>
    countryConfigs.find((config) => config.countryCode === country) || {},
  getAvailableCountries: (): string[] => countryConfigs.map((config) => config.countryCode || ''),
  getAvailableLocales: (): string[] =>
    countryConfigHelper
      .getAvailableCountries()
      .flatMap((countryCode) => countryConfigHelper.getCountryConfig(countryCode).langCodes ?? []),
};

export default countryConfigHelper;
